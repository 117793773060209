<template>
    <div class="col-4 col-lg-5 col-xs-12 col-md-6">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <v-btn class="ml-3 success" light @click="openCreateMonthlyExchangeRateDialog"><v-icon class="white--text" color="white lighten-1">add</v-icon>{{ $t('message.monthlyRates') }}</v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="exchange-rates"
                        table-id="exchangeRatesTable"
                        style="padding-top: 2px !important;"
                        @add-action="openCreateExchangeRateDialog()"
                    ></ExportTable>
                    <HelpPopUpV2 help-page-component="ExchangeRatesListing" :no-padding="true"></HelpPopUpV2>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.exchangeRates"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="allExchangeRates"
            :items-per-page="-1"
            :options.sync="tableOptions"
            :search="searchTerm"
            :sort-by="['Currencyrate.dated','Currency.code']"
            :sort-desc="[true,false]"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="exchangeRatesTable"
            item-key="Currencyrate.id"
        >
            <template v-slot:item.index="{ item }">
                <div class="text-center">{{ allExchangeRates.indexOf(item) + 1}}</div>
            </template>
            <template v-slot:item.Currencyrate.dated="{ item }">
                <span class="font-weight-bold">{{ formatDate(item.Currencyrate.dated) }}</span>
            </template>
            <template v-slot:item.Currencyrate.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditExchangeRateDialog(item.Currencyrate.id, formatDate(item.Currencyrate.dated) + ':' + item.Currency.code + ':'+ +item.Currencyrate.rate)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateExchangeRate') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteExchangeRate(item.Currencyrate.id, formatDate(item.Currencyrate.dated) + ':' + item.Currency.code + ':'+ +item.Currencyrate.rate)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteExchangeRate') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <AddExchangeRate
            :exchange-rate-id="selectedExchangeRateId"
            :exchange-rate-name="selectedExchangeRateName"
            :dialog.sync="editDialog"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="exchangeRateUpdated"
            @create-done="exchangeRateCreated"
        ></AddExchangeRate>
        <AddMonthlyExchangeRates
            :dialog.sync="createDialog"
            @dialog-closed="createDialogClosed"
            @create-done="monthlyExchangeRatesCreated"
        ></AddMonthlyExchangeRates>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters} from "vuex";
import {formatDate} from "Helpers/helpers";
import {api} from "Api";
const AddMonthlyExchangeRates = () => import("Components/Appic/AddMonthlyExchangeRates");
const AddExchangeRate = () => import("Components/Appic/AddExchangeRate");
const ExportTable = () => import("Components/Appic/ExportTable");
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "ExchangeRatesListing",
    components: {AddMonthlyExchangeRates, AddExchangeRate, ExportTable, HelpPopUpV2 },
    props: ['addDialog','reload'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            createDialog: null,
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            filterOptions: {
                office: 1
            },
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                exchangeRates: false
            },
            searchField: null,
            searchTerm: null,
            selectedExchangeRateId: null,
            selectedExchangeRateName: null,
            sortBy: ['Paymentterm.model' ,'Paymentterm.title'],
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalExchangeRates: 0,
            updateMode: false
        }
    },
    computed: {
        ...mapFields('exchangerate',{
            allExchangeRates: 'allExchangeRates'
        }),
        headers () {
            return [
                // {
                //     id: 0,
                //     text: this.$t('message.no'),
                //     value: 'index',
                //     class: 'light-green lighten-3 pa-1 width-1-pct',
                //     sortable: false
                // },
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Currencyrate.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.date'),
                    value: 'Currencyrate.dated',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.currency'),
                    value: 'Currency.code',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.rateAgainstRm'),
                    value: 'Currencyrate.rate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
            ]
        }
    },
    methods: {
        ...mapActions('exchangerate', {
            deleteExchangeRateById: 'deleteExchangeRateById',
            getAllExchangeRates: 'getAllExchangeRates',
            resetState: 'resetState'
        }),
        createDialogClosed() {
            this.createDialog = false;
            this.$emit('add-dialog-closed')
        },
        async deleteExchangeRate(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteExchangeRate') + ' ' +  title, this.$t('message.confirmations.continueExchangeRateCancelAction'), {color: 'orange'})){
                this.deleteExchangeRateById(val).then((status) => {
                    if(status == 'done'){
                        this.$toast.success(this.$t('message.successes.exchangeRateDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.resetState()
                        this.loadAllExchangeRates()
                    } else {
                        this.$toast.error(this.$t('message.errors.exchangeRateNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            }
        },
        exchangeRateCreated () {
            this.editDialog = false
            this.allExchangeRates = []
            this.resetState()
            this.$emit('reload')
        },
        exchangeRateUpdated () {
            this.editDialog = false
            this.allExchangeRates = []
            this.resetState()
            this.$emit('reload')
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        formatDate,
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        loadAllExchangeRates () {
            this.loading.exchangeRates = true
            this.getAllExchangeRates()
                .then(() => {
                    this.loading.exchangeRates = false
                })
        },
        monthlyExchangeRatesCreated() {
            this.createDialog = false
            this.allExchangeRates = []
            this.resetState()
            this.$emit('reload')
        },
        openCreateExchangeRateDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openCreateMonthlyExchangeRateDialog () {
            this.createDialog = true
        },
        openEditExchangeRateDialog (exchangeRateId, exchangeRateName) {
            this.selectedExchangeRateId = exchangeRateId
            this.selectedExchangeRateName = exchangeRateName
            this.updateMode = true
            this.editDialog = true
        },
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateExchangeRateDialog ()
            }
        }
    },
    created() {
        this.allExchangeRates = []
        this.resetState()
        this.loadAllExchangeRates()
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
    .text-start {
        padding-left: 3px !important;
        padding-top: 3px !important;
        padding-right: 3px !important;
        vertical-align: middle !important;
    }
    .truncate {
        display: inline-block;
        width: 100px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .v-data-table td {
        padding: 0 3px;
        font-size: 0.8rem !important;
        line-height: 0.8rem;
        vertical-align: center;
    }
    .v-data-table-header th {
        white-space: nowrap;
    }
</style>